.pill-button {
  padding: 8px 5%;
  background: rgb(72, 97, 243);
  border-radius: 10px;
  width: max-content;
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin: 10px auto;
}

table th {
  text-align: center !important;
}

table tr td {
  text-align: center !important;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

