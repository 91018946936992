.MuiTabs-root {
  background: #F3EDED;
  border-radius: 7px;
  margin: 5% 2% 2%;
  overflow: hidden;
}

.MuiTabs-indicator {
  background-color: white;
  top: 5px;
  height: 30px;
  min-height: 16px;
  border-radius: 6px;
  box-shadow: unset;
  border: unset;
}

.MuiTab-root.Mui-selected {
  color: #322C2C;
}
.MuiTab-root.Mui-selected:hover {
  color: #322C2C;
}

.MuiTabs-scroller {
  overflow: hidden !important;
}

